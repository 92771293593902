import { ImageModifiers } from '@nuxt/image'
import { createOperationsGenerator } from '@nuxt/image/dist/runtime'

export const operationsGenerator = createOperationsGenerator({
  joinWith: ',',
  formatter: (key: string, value: string) => `${key}=${value}`
})

export function getImage(src: string, { modifiers }: { modifiers?: ImageModifiers } = {}) {
  const operations = operationsGenerator(modifiers);
  if (operations.length > 0) {
    return {
      url: `https://chefstbl.com/cdn-cgi/image/${operations}/${src}`
    }
  }

  return {
    url: `https://chefstbl.com/cdn-cgi/image/fit=contain/${src}`
  }
}
