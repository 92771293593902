import { render, staticRenderFns } from "./website-footer.vue?vue&type=template&id=8908322a&scoped=true"
import script from "./website-footer.vue?vue&type=script&lang=ts"
export * from "./website-footer.vue?vue&type=script&lang=ts"
import style0 from "./website-footer.vue?vue&type=style&index=0&id=8908322a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8908322a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiIconLogo: require('/vercel/path0/components/ui/icon/logo.vue').default,UiLink: require('/vercel/path0/components/ui/link.vue').default,UiIconInstagram: require('/vercel/path0/components/ui/icon/instagram.vue').default,UiIconFacebook: require('/vercel/path0/components/ui/icon/facebook.vue').default,UiIconLinkedin: require('/vercel/path0/components/ui/icon/linkedin.vue').default,UiContainer: require('/vercel/path0/components/ui/container.vue').default,UiIconLock: require('/vercel/path0/components/ui/icon/lock.vue').default})
