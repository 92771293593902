
import Vue from 'vue';

export default Vue.extend({
  name: 'ui-link',
  props: {
    link: {
      type: String,
      default: '#'
    }
  }
})
