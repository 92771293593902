export const LoadingSpinner = () => import('../../components/loading-spinner.vue' /* webpackChunkName: "components/loading-spinner" */).then(c => wrapFunctional(c.default || c))
export const NavbarSmall = () => import('../../components/navbar-small.vue' /* webpackChunkName: "components/navbar-small" */).then(c => wrapFunctional(c.default || c))
export const Navbar = () => import('../../components/navbar.vue' /* webpackChunkName: "components/navbar" */).then(c => wrapFunctional(c.default || c))
export const WebsiteFooter = () => import('../../components/website-footer.vue' /* webpackChunkName: "components/website-footer" */).then(c => wrapFunctional(c.default || c))
export const CheckoutExperience = () => import('../../components/checkout/experience.vue' /* webpackChunkName: "components/checkout-experience" */).then(c => wrapFunctional(c.default || c))
export const CheckoutFaq = () => import('../../components/checkout/faq.vue' /* webpackChunkName: "components/checkout-faq" */).then(c => wrapFunctional(c.default || c))
export const CheckoutOrderItem = () => import('../../components/checkout/order-item.vue' /* webpackChunkName: "components/checkout-order-item" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPaymentOptions = () => import('../../components/checkout/payment-options.vue' /* webpackChunkName: "components/checkout-payment-options" */).then(c => wrapFunctional(c.default || c))
export const ChefDetailCard = () => import('../../components/chef/detail-card.vue' /* webpackChunkName: "components/chef-detail-card" */).then(c => wrapFunctional(c.default || c))
export const ChefExtraCard = () => import('../../components/chef/extra-card.vue' /* webpackChunkName: "components/chef-extra-card" */).then(c => wrapFunctional(c.default || c))
export const ChefFilter = () => import('../../components/chef/filter.vue' /* webpackChunkName: "components/chef-filter" */).then(c => wrapFunctional(c.default || c))
export const ChefImageCard = () => import('../../components/chef/image-card.vue' /* webpackChunkName: "components/chef-image-card" */).then(c => wrapFunctional(c.default || c))
export const ChefImageSlider = () => import('../../components/chef/image-slider.vue' /* webpackChunkName: "components/chef-image-slider" */).then(c => wrapFunctional(c.default || c))
export const ChefLoading = () => import('../../components/chef/loading.vue' /* webpackChunkName: "components/chef-loading" */).then(c => wrapFunctional(c.default || c))
export const ChefPricingCard = () => import('../../components/chef/pricing-card.vue' /* webpackChunkName: "components/chef-pricing-card" */).then(c => wrapFunctional(c.default || c))
export const ChefPricingList = () => import('../../components/chef/pricing-list.vue' /* webpackChunkName: "components/chef-pricing-list" */).then(c => wrapFunctional(c.default || c))
export const ChefReviewCard = () => import('../../components/chef/review-card.vue' /* webpackChunkName: "components/chef-review-card" */).then(c => wrapFunctional(c.default || c))
export const ChefReview = () => import('../../components/chef/review.vue' /* webpackChunkName: "components/chef-review" */).then(c => wrapFunctional(c.default || c))
export const FilterAddressBlock = () => import('../../components/filter/address-block.vue' /* webpackChunkName: "components/filter-address-block" */).then(c => wrapFunctional(c.default || c))
export const FilterAddressSearch = () => import('../../components/filter/address-search.vue' /* webpackChunkName: "components/filter-address-search" */).then(c => wrapFunctional(c.default || c))
export const FilterAddress = () => import('../../components/filter/address.vue' /* webpackChunkName: "components/filter-address" */).then(c => wrapFunctional(c.default || c))
export const FilterBaseFilterCard = () => import('../../components/filter/base-filter-card.vue' /* webpackChunkName: "components/filter-base-filter-card" */).then(c => wrapFunctional(c.default || c))
export const FilterCalendar = () => import('../../components/filter/calendar.vue' /* webpackChunkName: "components/filter-calendar" */).then(c => wrapFunctional(c.default || c))
export const FilterBar = () => import('../../components/filter/filter-bar.vue' /* webpackChunkName: "components/filter-bar" */).then(c => wrapFunctional(c.default || c))
export const FilterColumn = () => import('../../components/filter/filter-column.vue' /* webpackChunkName: "components/filter-column" */).then(c => wrapFunctional(c.default || c))
export const FilterPerson = () => import('../../components/filter/person.vue' /* webpackChunkName: "components/filter-person" */).then(c => wrapFunctional(c.default || c))
export const IndexCategory = () => import('../../components/index/category.vue' /* webpackChunkName: "components/index-category" */).then(c => wrapFunctional(c.default || c))
export const IndexChefHighlight = () => import('../../components/index/chef-highlight.vue' /* webpackChunkName: "components/index-chef-highlight" */).then(c => wrapFunctional(c.default || c))
export const IndexDescription = () => import('../../components/index/description.vue' /* webpackChunkName: "components/index-description" */).then(c => wrapFunctional(c.default || c))
export const IndexFaq = () => import('../../components/index/faq.vue' /* webpackChunkName: "components/index-faq" */).then(c => wrapFunctional(c.default || c))
export const IndexHero = () => import('../../components/index/hero.vue' /* webpackChunkName: "components/index-hero" */).then(c => wrapFunctional(c.default || c))
export const IndexOccasions = () => import('../../components/index/occasions.vue' /* webpackChunkName: "components/index-occasions" */).then(c => wrapFunctional(c.default || c))
export const IndexReferences = () => import('../../components/index/references.vue' /* webpackChunkName: "components/index-references" */).then(c => wrapFunctional(c.default || c))
export const IndexUsp = () => import('../../components/index/usp.vue' /* webpackChunkName: "components/index-usp" */).then(c => wrapFunctional(c.default || c))
export const UiAccordion = () => import('../../components/ui/accordion.vue' /* webpackChunkName: "components/ui-accordion" */).then(c => wrapFunctional(c.default || c))
export const UiButton = () => import('../../components/ui/button.vue' /* webpackChunkName: "components/ui-button" */).then(c => wrapFunctional(c.default || c))
export const UiCard = () => import('../../components/ui/card.vue' /* webpackChunkName: "components/ui-card" */).then(c => wrapFunctional(c.default || c))
export const UiCheckbox = () => import('../../components/ui/checkbox.vue' /* webpackChunkName: "components/ui-checkbox" */).then(c => wrapFunctional(c.default || c))
export const UiChefLoadingCard = () => import('../../components/ui/chef-loading-card.vue' /* webpackChunkName: "components/ui-chef-loading-card" */).then(c => wrapFunctional(c.default || c))
export const UiContactCard = () => import('../../components/ui/contact-card.vue' /* webpackChunkName: "components/ui-contact-card" */).then(c => wrapFunctional(c.default || c))
export const UiContainerXl = () => import('../../components/ui/container-xl.vue' /* webpackChunkName: "components/ui-container-xl" */).then(c => wrapFunctional(c.default || c))
export const UiContainer = () => import('../../components/ui/container.vue' /* webpackChunkName: "components/ui-container" */).then(c => wrapFunctional(c.default || c))
export const UiDatePicker = () => import('../../components/ui/date-picker.vue' /* webpackChunkName: "components/ui-date-picker" */).then(c => wrapFunctional(c.default || c))
export const UiError = () => import('../../components/ui/error.vue' /* webpackChunkName: "components/ui-error" */).then(c => wrapFunctional(c.default || c))
export const UiInputPhoneNumber = () => import('../../components/ui/input-phone-number.vue' /* webpackChunkName: "components/ui-input-phone-number" */).then(c => wrapFunctional(c.default || c))
export const UiInput = () => import('../../components/ui/input.vue' /* webpackChunkName: "components/ui-input" */).then(c => wrapFunctional(c.default || c))
export const UiLink = () => import('../../components/ui/link.vue' /* webpackChunkName: "components/ui-link" */).then(c => wrapFunctional(c.default || c))
export const UiModal = () => import('../../components/ui/modal.vue' /* webpackChunkName: "components/ui-modal" */).then(c => wrapFunctional(c.default || c))
export const UiNumberSelector = () => import('../../components/ui/number-selector.vue' /* webpackChunkName: "components/ui-number-selector" */).then(c => wrapFunctional(c.default || c))
export const UiOutlineButton = () => import('../../components/ui/outline-button.vue' /* webpackChunkName: "components/ui-outline-button" */).then(c => wrapFunctional(c.default || c))
export const UiPagination = () => import('../../components/ui/pagination.vue' /* webpackChunkName: "components/ui-pagination" */).then(c => wrapFunctional(c.default || c))
export const UiProgressRating = () => import('../../components/ui/progress-rating.vue' /* webpackChunkName: "components/ui-progress-rating" */).then(c => wrapFunctional(c.default || c))
export const UiRating = () => import('../../components/ui/rating.vue' /* webpackChunkName: "components/ui-rating" */).then(c => wrapFunctional(c.default || c))
export const UiReadMore = () => import('../../components/ui/read-more.vue' /* webpackChunkName: "components/ui-read-more" */).then(c => wrapFunctional(c.default || c))
export const UiSelect = () => import('../../components/ui/select.vue' /* webpackChunkName: "components/ui-select" */).then(c => wrapFunctional(c.default || c))
export const UiSwiperButtons = () => import('../../components/ui/swiper-buttons.vue' /* webpackChunkName: "components/ui-swiper-buttons" */).then(c => wrapFunctional(c.default || c))
export const UiSwitch = () => import('../../components/ui/switch.vue' /* webpackChunkName: "components/ui-switch" */).then(c => wrapFunctional(c.default || c))
export const UiTextarea = () => import('../../components/ui/textarea.vue' /* webpackChunkName: "components/ui-textarea" */).then(c => wrapFunctional(c.default || c))
export const ChefBookCompanyAddress = () => import('../../components/chef/book/company-address.vue' /* webpackChunkName: "components/chef-book-company-address" */).then(c => wrapFunctional(c.default || c))
export const ChefBookExperienceAddress = () => import('../../components/chef/book/experience-address.vue' /* webpackChunkName: "components/chef-book-experience-address" */).then(c => wrapFunctional(c.default || c))
export const ChefBookFaq = () => import('../../components/chef/book/faq.vue' /* webpackChunkName: "components/chef-book-faq" */).then(c => wrapFunctional(c.default || c))
export const ChefBookFilter = () => import('../../components/chef/book/filter.vue' /* webpackChunkName: "components/chef-book-filter" */).then(c => wrapFunctional(c.default || c))
export const ChefBookPersonalInformation = () => import('../../components/chef/book/personal-information.vue' /* webpackChunkName: "components/chef-book-personal-information" */).then(c => wrapFunctional(c.default || c))
export const ChefBookVatInvoice = () => import('../../components/chef/book/vat-invoice.vue' /* webpackChunkName: "components/chef-book-vat-invoice" */).then(c => wrapFunctional(c.default || c))
export const IndexCategoryCard = () => import('../../components/index/category/category-card.vue' /* webpackChunkName: "components/index-category-card" */).then(c => wrapFunctional(c.default || c))
export const IndexChefCard = () => import('../../components/index/chef-highlight/chef-card.vue' /* webpackChunkName: "components/index-chef-card" */).then(c => wrapFunctional(c.default || c))
export const IndexOccasionsOccasionCard = () => import('../../components/index/occasions/occasion-card.vue' /* webpackChunkName: "components/index-occasions-occasion-card" */).then(c => wrapFunctional(c.default || c))
export const IndexUspCard = () => import('../../components/index/usp/usp-card.vue' /* webpackChunkName: "components/index-usp-card" */).then(c => wrapFunctional(c.default || c))
export const UiIconCalendar = () => import('../../components/ui/icon/calendar.vue' /* webpackChunkName: "components/ui-icon-calendar" */).then(c => wrapFunctional(c.default || c))
export const UiIconChat = () => import('../../components/ui/icon/chat.vue' /* webpackChunkName: "components/ui-icon-chat" */).then(c => wrapFunctional(c.default || c))
export const UiIconCheckGreen = () => import('../../components/ui/icon/check-green.vue' /* webpackChunkName: "components/ui-icon-check-green" */).then(c => wrapFunctional(c.default || c))
export const UiIconCheck = () => import('../../components/ui/icon/check.vue' /* webpackChunkName: "components/ui-icon-check" */).then(c => wrapFunctional(c.default || c))
export const UiIconChefHat = () => import('../../components/ui/icon/chef-hat.vue' /* webpackChunkName: "components/ui-icon-chef-hat" */).then(c => wrapFunctional(c.default || c))
export const UiIconChevronDown = () => import('../../components/ui/icon/chevron-down.vue' /* webpackChunkName: "components/ui-icon-chevron-down" */).then(c => wrapFunctional(c.default || c))
export const UiIconChevronLeft = () => import('../../components/ui/icon/chevron-left.vue' /* webpackChunkName: "components/ui-icon-chevron-left" */).then(c => wrapFunctional(c.default || c))
export const UiIconChevronRight = () => import('../../components/ui/icon/chevron-right.vue' /* webpackChunkName: "components/ui-icon-chevron-right" */).then(c => wrapFunctional(c.default || c))
export const UiIconClockHand = () => import('../../components/ui/icon/clock-hand.vue' /* webpackChunkName: "components/ui-icon-clock-hand" */).then(c => wrapFunctional(c.default || c))
export const UiIconClose = () => import('../../components/ui/icon/close.vue' /* webpackChunkName: "components/ui-icon-close" */).then(c => wrapFunctional(c.default || c))
export const UiIconFacebook = () => import('../../components/ui/icon/facebook.vue' /* webpackChunkName: "components/ui-icon-facebook" */).then(c => wrapFunctional(c.default || c))
export const UiIconFilter = () => import('../../components/ui/icon/filter.vue' /* webpackChunkName: "components/ui-icon-filter" */).then(c => wrapFunctional(c.default || c))
export const UiIconInfo = () => import('../../components/ui/icon/info.vue' /* webpackChunkName: "components/ui-icon-info" */).then(c => wrapFunctional(c.default || c))
export const UiIconInstagram = () => import('../../components/ui/icon/instagram.vue' /* webpackChunkName: "components/ui-icon-instagram" */).then(c => wrapFunctional(c.default || c))
export const UiIconLinkedin = () => import('../../components/ui/icon/linkedin.vue' /* webpackChunkName: "components/ui-icon-linkedin" */).then(c => wrapFunctional(c.default || c))
export const UiIconLockWithIcon = () => import('../../components/ui/icon/lock-with-icon.vue' /* webpackChunkName: "components/ui-icon-lock-with-icon" */).then(c => wrapFunctional(c.default || c))
export const UiIconLock = () => import('../../components/ui/icon/lock.vue' /* webpackChunkName: "components/ui-icon-lock" */).then(c => wrapFunctional(c.default || c))
export const UiIconLogo = () => import('../../components/ui/icon/logo.vue' /* webpackChunkName: "components/ui-icon-logo" */).then(c => wrapFunctional(c.default || c))
export const UiIconMenu = () => import('../../components/ui/icon/menu.vue' /* webpackChunkName: "components/ui-icon-menu" */).then(c => wrapFunctional(c.default || c))
export const UiIconPencil = () => import('../../components/ui/icon/pencil.vue' /* webpackChunkName: "components/ui-icon-pencil" */).then(c => wrapFunctional(c.default || c))
export const UiIconPeople = () => import('../../components/ui/icon/people.vue' /* webpackChunkName: "components/ui-icon-people" */).then(c => wrapFunctional(c.default || c))
export const UiIconSearch = () => import('../../components/ui/icon/search.vue' /* webpackChunkName: "components/ui-icon-search" */).then(c => wrapFunctional(c.default || c))
export const UiIconShield = () => import('../../components/ui/icon/shield.vue' /* webpackChunkName: "components/ui-icon-shield" */).then(c => wrapFunctional(c.default || c))
export const UiIconSpecifications = () => import('../../components/ui/icon/specifications.vue' /* webpackChunkName: "components/ui-icon-specifications" */).then(c => wrapFunctional(c.default || c))
export const UiIconSpray = () => import('../../components/ui/icon/spray.vue' /* webpackChunkName: "components/ui-icon-spray" */).then(c => wrapFunctional(c.default || c))
export const UiIconStarEmpty = () => import('../../components/ui/icon/star-empty.vue' /* webpackChunkName: "components/ui-icon-star-empty" */).then(c => wrapFunctional(c.default || c))
export const UiIconStarHalf = () => import('../../components/ui/icon/star-half.vue' /* webpackChunkName: "components/ui-icon-star-half" */).then(c => wrapFunctional(c.default || c))
export const UiIconStar = () => import('../../components/ui/icon/star.vue' /* webpackChunkName: "components/ui-icon-star" */).then(c => wrapFunctional(c.default || c))
export const UiIconWebwinkelkeur = () => import('../../components/ui/icon/webwinkelkeur.vue' /* webpackChunkName: "components/ui-icon-webwinkelkeur" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
