import {ActionTree, GetterTree, MutationTree} from 'vuex'
import Vue from 'vue'
import {AddressInterface} from '~/components/filter/address-search.vue'
import {ExtraSelectedInterface} from '~/components/chef/extra-card.vue'
import {ChefInterface} from "~/store/chef";

export interface ChefExtraOptionInterface {
  id: number,
  title: string,
  description: string,
  price: string,
  price_type: 'onetime' | 'byguest',
  quantity_buttons: boolean,
}

export interface ListingDetailInterface {
  id: number,
  title: string,
  subtitle: string,
  slug: string,
  booking_days_in_advance: number,
  number_of_units: number,
  arrangement_unit: string,
  unit_text: string,
  images: { title: string, url: string }[],
  min_guests: number,
  max_guests: number,
  price_from: string,
  price_from_in_cents: number,
  discount_percentage: number,
  discount_price_from: string,
  discount_price_from_in_cents: number,
  review_amount: number,
  review_score: number,
  chef_score: number,
  location: string,
  super_chef: false,
  description: string,
  description_custom: null,
  what_is_included: string[],
  booker_action_items: string[],
  radius_in_km: number,
  work_area_description: string,
  chef: ChefInterface,
  extras: ChefExtraOptionInterface[],
  example_menu: string[],
  features: {
    listing_feature: string[],
    listing_category: string[],
    'kitchen-type': string[]
  }
}

export interface ChefPricingInterface {
  extras: {
    id: number
    title: string
    quantity: number
    price: string
    price_in_cents: number
    total: string
    total_in_cents: number
  }[],
  arrangements: {
    quantity: number
    price: string
    price_in_cents: number
    total: string
    total_in_cents: number
  },
  total_extras: string
  total_extras_in_cents: number
  subtotal: string
  subtotal_in_cents: number
  travel_costs: string
  travel_costs_in_cents: number
  service_fee: string
  service_fee_in_cents: number
  discount: string
  discount_in_cents: string
  total: string
  total_in_cents: number
}

export interface ChefPricingRequestInterface {
  coupon?: string;
  guests: number;
  date: string;
  address: Partial<AddressInterface>;
  extras: {
    id: number;
    quantity: number;
  }[]
}

export const state = () => ({
  listing: {} as { [key: string]: ListingDetailInterface },
  listingExtra: {} as { [key: string]: ExtraSelectedInterface[] },
  listingPricing: {} as { [key: string]: ChefPricingInterface },
})

export type RootState = ReturnType<typeof state>

export const getters: GetterTree<RootState, RootState> = {
  listing: state => state.listing,
  byIdOrSlug: state => (key: string) => state.listing[key],
  extraByListingId: state => (key: string) => state.listingExtra[key],
  pricingByListingId: state => (key: string) => state.listingPricing[key],
}

export const mutations: MutationTree<RootState> = {
  UPDATE_LISTING: (state, data: { key: string, data: ListingDetailInterface }) => {
    // todo collision with "key" = slug and key=id, see below.
    Vue.set(state.listing, data.key, data.data)
  },
  UPDATE_SELECTED_EXTRA: (state, data: { key: string, data: ExtraSelectedInterface[] }) => {
    Vue.set(state.listingExtra, data.key, data.data)
  },
  UPDATE_LISTING_PRICING: (state, data: { key: string, data: ChefPricingInterface }) => {
    Vue.set(state.listingPricing, data.key, data.data)
  },
}

export const actions: ActionTree<RootState, RootState> = {
  async fetchListing({commit, getters, state}, {id, refreshCache = false}: { id: string, refreshCache: boolean }) {
    if ((getters.byIdOrSlug(id) !== undefined && refreshCache) || getters.byIdOrSlug(id) === undefined) {
      await this.$axios.$get<{ data: ListingDetailInterface }>(`/v1/listing/${id}`).then((response) => {
        commit('UPDATE_LISTING', {
          key: id, // todo see above, maybe always use id/slug ? response.data.id ... or two methods, get by slug/id
          data: response.data
        })
      }).catch((error) => {
        console.log('error', error)
      })
    }
  },
  async calculateChefPrice({commit, state}, {id, data}) {
    const response = await this.$axios.$post<{ data: ChefPricingInterface }>(`/v1/listing/${id}/pricing`, data)
    commit('UPDATE_LISTING_PRICING', {
      key: id,
      data: response.data
    })
  }
}
