export default {
  'general': {
    'search': 'Zoek',
    'loading': 'Laden...',
    'next': 'volgende',
    'read-more': 'Lees meer',
    'hide': 'verberg',
    'reviews': 'review | reviews',
    'multiple-reviews': 'reviews',
    'single-review': 'review',
    'more-info': 'Meer informatie',
    'nothing-found': 'We hebben geen resultaten gevonden die aan je zoekcriteria voldoen.',
    'not-sure': 'Ik weet het nog niet',
    'super-chef': 'Superchef',
    'error': {
      'loading-data': 'Fout bij het ophalen van de data',
      'generic': 'Er is een fout opgetreden.'
    },
    'arrangement': {
      'amount': 'Geen arrangement | {n} arrangement | {n} arrangementen'
    }
  },
  'contact-card': {
    'title': 'Hulp nodig?',
    'sub-title': 'Ons team helpt je graag.',
    'whatsapp': 'Stuur een',
    'call': 'Bel ons op',
    'mail': 'Mail naar',
  },
  'chef': {
    'back-to-overview': '< terug naar overzicht',
    'reserve-title': 'Prijsberekening',
    'detail': {
      'member-since': 'Lid sinds',
      'company-verified': 'Bedrijfsgegevens geverifieerd',
      'question-for-chef': 'Neem contact op met de cateraar',
      'score': '{review_score} uit <span class="text-primary">{review_amount} reviews</span>',
      'safety-notice': 'Om veilig te betalen en communiceren, is het belangrijk dat je altijd gebruik maakt van de CHEFSTBL website.'
    },
    'extra': {
      'each-person': 'Per persoon'
    },
    'image': {
      'view-more-images': 'Bekijk {imageCount} foto\'s'
    },
    'price': {
      'calculate-price': 'Bereken de prijs',
      'price-per-person': 'Arrangement',
      'extra-options': 'Extra opties',
      'travel-cost': 'Reiskosten',
      'service-cost': 'Servicekosten',
      'reserve-now': 'Boek direct',
      'quotation': 'Offerte aanvragen',
      'discount': 'Korting',
      'total': 'Totaal voor {personCount} personen',
    },
    'error': {
      'min-guests': 'Minimaal {guests} personen',
      'max-guests': 'Maximaal {guests} personen'
    }
  },
  'search': {
    'find-your-chef': 'Vind je cateraar',
    'filter-chefs': 'Filter {count} arrangement | Filter {count} arrangementen',
    'filters': {
      'title': 'Filters',
      'clear-filters': 'Wis alle filters',
      'previous-filters': 'Dit zijn je eerder gekozen filters',
    },
    'refine-search': {
      'sort': '{chefs} arrangementen gevonden',
      'title': '<span class="text-primary">{chefs} arrangementen gevonden.</span> Te veel resultaten?',
      'description-filters': 'Voeg extra filters toe en verfijn je zoekopdracht.',
      'description': 'Om de juiste arrangementen te kunnen aanbevelen willen we je vragen om de locatie, datum en het aantal personen in te vullen.'
    }
  },
  'index': {
    'hero': {
      'find-chef': 'Catering voor elke gelegenheid',
      'find-your-chef': 'Vind jouw cateraar',
      'find-your-chef-mobile': 'Waar?',
      'view-chefs': 'Bekijk cateraars',
      'usp': {
        'chef-amount': '180+ arrangementen',
        'free-reservation': 'Vrijblijvend reserveren',
        'directly-arranged': 'Direct geregeld'
      },
      'address': {
        'where': 'Waar?',
        'placeholder': 'Bijvoorbeeld: Den Bosch',
        'recently': 'Recent ingevuld',
        'no-recent': 'Geen recente adressen'
      },
      'calendar': {
        'when': 'Wanneer?',
        'choose-date': 'Kies een datum'
      },
      'person': {
        'how-many': 'Hoeveel personen?',
        'amount': 'Aantal personen',
        'age-limit': '13 jaar of ouder',
        'child-meal': 'Kindermenu’s (tot 12 jaar) zijn als extra toe te voegen bij je reservering.',
        'change-amount-info': 'Je kan later het aantal personen nog wijzigen.'
      }
    },
    'reference': {
      'known-of': 'CHEFSTBL is bekend van',
      'review-amount': 'klantbeoordelingen'
    },
    'category': {
      'title': 'Kies je soort arrangement'
    },
    'occasions': {
      'title': 'Ik zoek een cateraar voor een:',
      'view': 'Bekijk',
    },
    'usp': {
      'title': 'Daarom boek je bij CHEFSTBL',
      'sub-title': 'Bij CHEFSTBL maken we het zoeken, vinden en boeken van een catering heel gemakkelijk.'
    },
    'chef-card': {
      'price_from': 'Vanaf <span class="font-bold">{priceFrom} p.p.</span>',
      'price': '<span class="font-bold">{price} p.p.</span>',
    },
    'chef-highlight': {
      'title': 'Nieuwste arrangementen',
      'view-all': 'Bekijk alle arrangementen'
    },
    'faq': {
      'title': 'Veelgestelde vragen',
      'all-link': 'Bekijk alle veelgestelde vragen'
    },
  },
  'filter': {
    'person': {
      'amount': 'Geen personen | {n} persoon | {n} personen'
    }
  }
}
