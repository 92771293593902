import {AddressInterface} from '~/components/filter/address-search.vue'
import {clone, isArray, isNil, isNumber} from 'lodash'
import moment from 'moment'

export interface FilterData {
  title?: string;
  super_chef?: boolean;
  offer?: boolean;
  price_min?: number;
  price_max?: number;
  guests?: number;
  date?: string;
  category_features?: number[],
  address?: Partial<AddressInterface>
}

export interface StateFilters {
  calendar?: Date,
  person?: number,
  selectedExtras?: { id: number, quantity: number }[],
  page?: number;
  address?: Partial<AddressInterface>,
  dynamicFilters: { [key: string]: number[] | number | string },
}

export const formatAddressInput = (address: AddressInterface): string => {
  const title = []

  if (address.street) {
    let fullStreet = [
      address.street
    ];

    if (address.houseNumber) {
      fullStreet.push(address.houseNumber);
    }

    title.push(fullStreet.join(' '));
  }

  if (address.addition) {
    title.push(address.addition);
  }

  if (address.city) {
    title.push(address.city);
  }

  if (address.country) {
    title.push(address.country);
  }

  return title.join(', ')
}

export const formatInputData = (data: StateFilters): FilterData => {
  let filterObject: FilterData = {}
  const ids: number[] = []

  // Explicit declaration to prevent unwanted pushes, e.g. from utm_campaign
  const allowedKeys = [
    'kitchen-type',
    'listing_feature',
    'listing_category',
    'specialization',
    'gelegenheden',
  ];

  Object.keys(data.dynamicFilters).forEach((key: string) => {
    if (allowedKeys.indexOf(key) == -1) {
      return;
    }

    const item = data.dynamicFilters[key]
    if (isArray(item)) {
      item.forEach(item => {
        // @ts-ignore
        let parsedInt = parseInt(item.key, 10);
        if (!isNaN(parsedInt)) {
          ids.push(parsedInt)
        }
      })
    } else {
      if (!isNil(item)) {
        if (isNumber(item)) {
          ids.push(item)
        } else {
          let parsedInt = parseInt(item, 10)
          if (!isNaN(parsedInt)) {
            ids.push(parsedInt)
          }
        }
      }
    }
  })

  filterObject.category_features = ids

  if (!isNil(data.dynamicFilters.title) && (data.dynamicFilters.title as string).length > 0) {
    filterObject.title = data.dynamicFilters.title as string
  }

  if (!isNil(data.dynamicFilters.price_min) && data.dynamicFilters.price_min) {
    filterObject.price_min = data.dynamicFilters.price_min as unknown as number
  }

  if (!isNil(data.dynamicFilters.price_max) && data.dynamicFilters.price_max) {
    filterObject.price_max = data.dynamicFilters.price_max as unknown as number
  }

  if (!isNil(data.dynamicFilters.super_chef) && data.dynamicFilters.super_chef) {
    filterObject.super_chef = data.dynamicFilters.super_chef as unknown as boolean
  }

  if (!isNil(data.dynamicFilters.offer) && data.dynamicFilters.offer) {
    filterObject.offer = data.dynamicFilters.offer as unknown as boolean
  }

  if (data.calendar) {
    let date = clone(data.calendar)

    if (isNumber(date)) {
      date = new Date(date)
    }

    filterObject.date = `${moment(date).format('YYYY-MM-DD')}T19:00`
  }

  if (data.person && data.person > 0) {
    filterObject.guests = data.person
  }

  if (data.address) {
    const clonedAddress = JSON.parse(JSON.stringify(data.address))

    delete clonedAddress.title
    delete clonedAddress.fullTitle
    filterObject.address = {
      ...clonedAddress
    }
  }

  return filterObject
}
