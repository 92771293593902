import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7656ad3e = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "" */))
const _6fafd14b = () => interopDefault(import('../pages/location.vue' /* webpackChunkName: "pages/location" */))
const _0a451aeb = () => interopDefault(import('../pages/location-overview.vue' /* webpackChunkName: "pages/location-overview" */))
const _1855c6b6 = () => interopDefault(import('../pages/checkout/order-pay.vue' /* webpackChunkName: "pages/checkout/order-pay" */))
const _1e510643 = () => interopDefault(import('../pages/checkout/order-received.vue' /* webpackChunkName: "pages/checkout/order-received" */))
const _98173ca8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6fb748ca = () => interopDefault(import('../pages/chef/_id/index.vue' /* webpackChunkName: "pages/chef/_id/index" */))
const _6d9f56b1 = () => interopDefault(import('../pages/profile/_id/index.vue' /* webpackChunkName: "pages/profile/_id/index" */))
const _0f1a42be = () => interopDefault(import('../pages/chef/_id/book.vue' /* webpackChunkName: "pages/chef/_id/book" */))
const _38209a86 = () => interopDefault(import('../pages/profile/_id/reviews/index.vue' /* webpackChunkName: "pages/profile/_id/reviews/index" */))
const _7f82d96c = () => interopDefault(import('../pages/profile/_id/reviews/write.vue' /* webpackChunkName: "pages/profile/_id/reviews/write" */))
const _231b6081 = () => interopDefault(import('../pages/chef/_id/requested/_reservationId.vue' /* webpackChunkName: "pages/chef/_id/requested/_reservationId" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bbq-met-kok-aan-huis",
    component: _7656ad3e,
    meta: {"type":"type-chef","item":"bbq-met-kok"},
    name: "search_bbq-met-kok___nl"
  }, {
    path: "/bbq-zonder-kok-aan-huis",
    component: _7656ad3e,
    meta: {"type":"type-chef","item":"bbq-zonder-kok"},
    name: "search_bbq-zonder-kok___nl"
  }, {
    path: "/bekijk-alle-chefs",
    component: _7656ad3e,
    name: "search_nl___nl"
  }, {
    path: "/borrelhapjes-aan-huis",
    component: _7656ad3e,
    meta: {"type":"type-chef","item":"borrel-en-bites"},
    name: "search_borrel-en-bites___nl"
  }, {
    path: "/buffet-aan-huis",
    component: _7656ad3e,
    meta: {"type":"type-chef","item":"buffet"},
    name: "search_buffet___nl"
  }, {
    path: "/foodtruck-aan-huis",
    component: _7656ad3e,
    meta: {"type":"type-chef","item":"foodtruck"},
    name: "search_foodtruck___nl"
  }, {
    path: "/gelegenheden",
    component: _7656ad3e,
    meta: {"type":"type-chef","item":"gelegenheden"},
    name: "search_gelegenheden___nl"
  }, {
    path: "/kok-aan-huis",
    component: _7656ad3e,
    meta: {"type":"type-chef","item":"private-dining"},
    name: "search_kok-aan-huis___nl"
  }, {
    path: "/kok-aan-huis",
    component: _7656ad3e,
    meta: {"type":"type-chef","item":"private-dining"},
    name: "search_private-dining___nl"
  }, {
    path: "/kookworkshop-aan-huis",
    component: _7656ad3e,
    meta: {"type":"type-chef","item":"workshop"},
    name: "search_workshop___nl"
  }, {
    path: "/location",
    component: _6fafd14b,
    name: "location___nl"
  }, {
    path: "/location-overview",
    component: _0a451aeb,
    name: "location-overview___nl"
  }, {
    path: "/proeverij-aan-huis",
    component: _7656ad3e,
    meta: {"type":"type-chef","item":"tasting"},
    name: "search_tasting___nl"
  }, {
    path: "/search",
    component: _7656ad3e,
    name: "search___nl"
  }, {
    path: "/shared-dinner-aan-huis",
    component: _7656ad3e,
    meta: {"type":"type-chef","item":"shared-dinner"},
    name: "search_shared-dinner___nl"
  }, {
    path: "/walking-dinner-aan-huis",
    component: _7656ad3e,
    meta: {"type":"type-chef","item":"walking-dinner"},
    name: "search_walking-dinner___nl"
  }, {
    path: "/checkout/order-pay",
    component: _1855c6b6,
    name: "checkout-order-pay___nl"
  }, {
    path: "/checkout/order-received",
    component: _1e510643,
    name: "checkout-order-received___nl"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___nl"
  }, {
    path: "/checkout/order-pay/:id",
    component: _1855c6b6,
    name: "order_pay___nl"
  }, {
    path: "/checkout/order-received/:id",
    component: _1e510643,
    name: "order_received___nl"
  }, {
    path: "/chef/:id",
    component: _6fb748ca,
    name: "chef-id___nl"
  }, {
    path: "/profile/:id",
    component: _6d9f56b1,
    name: "profile-id___nl"
  }, {
    path: "/chef/:id?/book",
    component: _0f1a42be,
    name: "chef-id-book___nl"
  }, {
    path: "/chef/:id/quotation",
    component: _0f1a42be,
    name: "quotation___nl"
  }, {
    path: "/profile/:id?/reviews",
    component: _38209a86,
    name: "profile-id-reviews___nl"
  }, {
    path: "/profile/:id?/reviews/write",
    component: _7f82d96c,
    name: "profile-id-reviews-write___nl"
  }, {
    path: "/chef/:id?/requested/:reservationId?",
    component: _231b6081,
    name: "chef-id-requested-reservationId___nl"
  }, {
    path: "/:type-locaties",
    component: _0a451aeb,
    name: "location-overview___nl"
  }, {
    path: "/catering-:location",
    component: _6fafd14b,
    alias: ["/buffet-aan-huis-:location/","/kok-aan-huis-:location/","/walking-dinner-aan-huis-:location/","/bbq-:location/"],
    name: "location___nl"
  }, {
    path: "/:type/:item",
    component: _7656ad3e,
    name: "search_params___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
