import Vue from 'vue'

const VeeValidate = require('vee-validate')
const nl = require('vee-validate/dist/locale/nl.json');

import {required, required_if, email, min_value, max_value, regex} from 'vee-validate/dist/rules';

// https://vee-validate.logaretm.com/v3/guide/rules.html#rules
VeeValidate.extend('required', required);
VeeValidate.extend('required_if', required_if);
VeeValidate.extend('email', email);
VeeValidate.extend('min_value', min_value);
VeeValidate.extend('max_value', max_value);
VeeValidate.extend('regex', regex);

// Install locales
VeeValidate.localize({
  nl,
})

// Set locale
VeeValidate.localize('nl')

Vue.component('ValidationProvider', VeeValidate.ValidationProvider);
Vue.component('ValidationObserver', VeeValidate.ValidationObserver);
