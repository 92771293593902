
import Vue from 'vue';

export default Vue.extend({
  name: 'websiteFooter',
  data() {
    return {
      links: [
        [
          'Amsterdam',
          'Rotterdam',
          'Den Haag',
          'Utrecht',
          'Eindhoven'
        ],
        [
          'Den Bosch',
          'Tilburg',
          'Almere',
          'Groningen',
          'Breda'
        ],
        [
          'Nijmegen',
          'Enschede',
          'Apeldoorn',
          'Maastricht',
        ],
        [
          'Gelderland',
          'Noord-Brabant',
          'Limburg'
        ]
      ]
    }
  }
})
