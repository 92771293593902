import {ChefCardInterface} from '~/components/index/chef-highlight.vue'
import {GetterTree, ActionTree, MutationTree} from 'vuex'
import {formatInputData, StateFilters} from '~/util/format-filter-data'
import Vue from 'vue';
import {PaginationMeta} from '~/components/ui/pagination.vue'
import {FilterResponse} from '~/components/filter/filter-column.vue'

export enum SortingKey {
  RECOMMENDED = 'recommended',
  PRICE = 'price',
  DISTANCE = 'distance',
  REVIEW_AMOUNT = 'review_amount',
  CHEF_SCORE = 'chef_score',
  NEWEST = 'newest',
}

export const state = () => ({
  chefs: [] as ChefCardInterface[],
  paginationMeta: null as PaginationMeta | null,
  sortingKey: SortingKey.RECOMMENDED,
  filter: {} as StateFilters,
  filterData: undefined as FilterResponse | undefined,
});

export type RootState = ReturnType<typeof state>

export const getters: GetterTree<RootState, RootState> = {
  chefs: state => state.chefs,
  filter: state => state.filter,
  filterData: state => state.filterData,
  pagination: state => state.paginationMeta,
  sortingKey: state => state.sortingKey
}

export const mutations: MutationTree<RootState> = {
  UPDATE_LISTINGS: (state, chefs: ChefCardInterface[]) => (state.chefs = chefs),
  UPDATE_PAGINATION_DATA: (state, paginationData: PaginationMeta) => (state.paginationMeta = paginationData),
  UPDATE_FILTERS: (state, filters: StateFilters) => {
    if (filters.page === undefined) {
      filters.page = state.filter.page ?? 1;
    }

    Vue.set(state, 'filter', {
      ...state.filter,
      ...filters,
      dynamicFilters: (filters.dynamicFilters && Object.keys(filters.dynamicFilters).length < 1) ? {} : {
        ...(state.filter.dynamicFilters ?? {}),
        ...(filters.dynamicFilters ?? {}),
      }
    })
  },
  UPDATE_FILTER_DATA: (state, filters: FilterResponse) => {
    Vue.set(state, 'filterData', filters);
  },
  UPDATE_SORTING_KEY: (state, key: SortingKey) => {
    state.sortingKey = key
    state.filter.page = 1
  },
  CLEAR_FILTERS: (state) => (Vue.set(state, 'filter', {})),
}

export const actions: ActionTree<RootState, RootState> = {
  async fetchFilters({commit}) {
    const response = await this.$axios.$get<{ data: FilterResponse }>(`/v1/filters`);
    commit('UPDATE_FILTER_DATA', response.data);

    return response.data;
  },
  async fetchListings({commit, state}) {
    const response = await this.$axios.$post<{ data: ChefCardInterface[], meta: PaginationMeta }>(`/v1/search/listing`, {
      filter: formatInputData(state.filter),
      page: state.filter.page ?? 1,
      'sort_key': state.sortingKey
    })

    commit('UPDATE_LISTINGS', response.data);
    commit('UPDATE_PAGINATION_DATA', response.meta);
  },
}
